import { Button, ButtonProps, RichTextBlock } from '@lib/machine-parts/storefront/ui';
import { RichTextBlockProps } from '@lib/machine-parts/storefront/utils';

export interface CheckoutButtonProps extends ButtonProps {
    termsAndConditions: RichTextBlockProps['value'];
}

export function CheckoutButton({ termsAndConditions, children, ...buttonProps }: CheckoutButtonProps) {
    return (
        <div className="w-full">
            <Button {...buttonProps} className={`w-full ${buttonProps.className ?? ''}`}>
                {children}
            </Button>

            <div className={`text-gray-500 text-sm`}>
                <RichTextBlock value={termsAndConditions} />
            </div>
        </div>
    );
}
