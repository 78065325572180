import { PropsWithChildren } from 'react';

interface CartPageProps {
    title: string;
}

export function CartPageLayout({ title, children }: PropsWithChildren<CartPageProps>) {
    return (
        <div className="relative overflow-hidden flex justify-center items-center py-14 px-4 lg:px-16 w-full space-y-8 lg:space-y-14">
            <div className={`max-w-7xl w-full`}>
                <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-none pb-10">{title}</h1>

                <div className="flex-grow flex flex-col lg:flex-row justify-between gap-8">{children}</div>
            </div>
        </div>
    );
}
