'use client';

import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { CartLineUpdateInput } from '@shopify/hydrogen-react/storefront-api-types';
import { ButtonVariant, EditIcon, CartLineInputField } from '@lib/machine-parts/storefront/ui';
import { CartLineQuantity, CartLineQuantityAdjustButton } from '@shopify/hydrogen-react';

import { CartLineImage } from '../../atoms';
import { useMemo } from 'react';

export enum DesignType {
    checkout,
    cart,
    cart_sidebar,
}

interface CartLineProps {
    designType?: DesignType;
    disableDeletion?: boolean;
    productLink: string;
    productImageUrl?: string;
    productSKU: string;
    productTitle: string;
    productUnitPrice: string;
    productTotalPrice: string;
    itemNo: string;
    quantity?: number;
    merchandise?: { id?: string };
    lines?: ({ id?: string; merchandise?: { id?: string }; quantity?: number } | undefined)[];
    onNavigate?: () => void;
    onIncrease?: () => void;
    onDecrease?: () => void;
    onRemove?: () => void;
    onInputType?: (amount: number) => void;
    linesUpdate: (lines: CartLineUpdateInput[]) => void;
}

export function CartLine({
    designType = DesignType.cart,
    disableDeletion = false,
    productLink,
    productImageUrl,
    productSKU,
    productTitle,
    productUnitPrice,
    productTotalPrice,
    itemNo,
    quantity,
    merchandise,
    lines,
    onNavigate,
    onIncrease,
    onDecrease,
    onRemove,
    onInputType,
    linesUpdate,
}: CartLineProps) {
    const isCartPageDesign = useMemo(() => designType === DesignType.cart, [designType]);
    const isCartSideBarDesign = useMemo(() => designType === DesignType.cart_sidebar, [designType]);

    return (
        <div className={`bg-white px-8 ${isCartSideBarDesign && 'border-b border-gray-300'}`} data-testid="cart-item">
            <div className="flex flex-row gap-8 py-7 text-brand-black">
                <div className="flex-shrink-0 flex items-center">
                    <Link
                        href={productLink}
                        mixpanel={{ eventName: `Cart Product Image To ${productSKU} Button` }}
                        variant={ButtonVariant.None}
                        onClick={onNavigate}
                    >
                        <CartLineImage
                            imageUrl={productImageUrl}
                            {...(!isCartPageDesign && { width: 64, height: 64 })}
                        />
                    </Link>
                </div>
                <div className="flex flex-row flex-1 justify-between flex-grow-1 gap-1">
                    <div className={`flex flex-col flex-1 lg:gap-4`}>
                        <div className={`flex flex-row flex-1 justify-between lg:gap-8`}>
                            <div className={`flex flex-col items-start`}>
                                <Link
                                    href={productLink}
                                    className="hover:underline"
                                    mixpanel={{
                                        eventName: `Cart Product Title To ${productSKU} Button`,
                                    }}
                                    variant={ButtonVariant.None}
                                >
                                    <div className="flex flex-col">
                                        <span
                                            className={`${
                                                isCartPageDesign && 'text-lg lg:text-xl'
                                            } font-heading font-bold`}
                                        >
                                            {productTitle}
                                        </span>
                                    </div>
                                </Link>

                                <div className="space-y-1 text-sm lg:text-base">{itemNo}</div>
                            </div>

                            {isCartPageDesign && (
                                <div className={`flex flex-col items-end`}>
                                    <div className="text-md lg:text-lg whitespace-nowrap font-heading font-bold lg:col-span-3 lg:mb-0 flex flex-row gap-2">
                                        {productUnitPrice}
                                    </div>
                                    <div className="text-md lg:text-lg whitespace-nowrap font-heading font-bold lg:col-span-3 lg:mb-0 flex flex-row gap-2">
                                        {productTotalPrice}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`flex justify-between lg:gap-2 flex-col lg:flex-row lg:items-center`}>
                            {!isCartPageDesign && (
                                <div className="flex flex-col">
                                    <div className="flex-1 leading-tight whitespace-nowrap text-base md:text-sm lg:text-lg font-heading lg:col-span-3 lg:mb-0 text-brand-brown">
                                        {productUnitPrice}
                                    </div>
                                    <div className="flex-1 leading-tight whitespace-nowrap text-base md:text-sm lg:text-lg font-heading lg:col-span-3 lg:mb-0 text-brand-brown">
                                        {productTotalPrice}
                                    </div>
                                </div>
                            )}
                            <div className={`flex flex-row justify-between lg:gap-2 items-center`}>
                                {isCartSideBarDesign ? (
                                    <>
                                        <Link
                                            href="/cart"
                                            mixpanel={{ eventName: `Cart Edit ${productSKU} Button` }}
                                            className={`text-brand-brown flex items-center gap-2`}
                                            onClick={onNavigate}
                                            variant={ButtonVariant.None}
                                        >
                                            <CartLineQuantity
                                                as="div"
                                                className="text-center font-bold"
                                                data-testid="quantity"
                                            />
                                            <EditIcon className={`h-4`} />
                                        </Link>
                                    </>
                                ) : (
                                    <div className="grid grid-cols-3 md:h-10 grid-rows-1 border border-gray-300 divide-x divide-gray-300">
                                        <CartLineQuantityAdjustButton
                                            adjust="decrease"
                                            disabled={quantity === 1 && disableDeletion}
                                            aria-label="Decrease quantity"
                                            className="p-2 disabled:pointer-events-all disabled:cursor-wait hover:bg-gray-100/50 disabled:hover:bg-transparent"
                                            onClick={onDecrease}
                                            data-testid="decrease-quantity"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </CartLineQuantityAdjustButton>
                                        <CartLineInputField
                                            quantity={quantity}
                                            merchandise={merchandise}
                                            lines={lines}
                                            linesUpdate={linesUpdate}
                                            onInputType={onInputType}
                                        />
                                        <CartLineQuantityAdjustButton
                                            adjust="increase"
                                            aria-label="Increase quantity"
                                            className="p-2 text-gray-400 disabled:pointer-events-all disabled:cursor-wait hover:bg-gray-100/50 disabled:hover:bg-transparent"
                                            onClick={onIncrease}
                                            data-testid="increase-quantity"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </CartLineQuantityAdjustButton>
                                    </div>
                                )}
                                {!disableDeletion && (
                                    <CartLineQuantityAdjustButton
                                        adjust="remove"
                                        aria-label="Remove from cart"
                                        className="disabled:pointer-events-all disabled:cursor-wait"
                                        onClick={onRemove}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                            />
                                        </svg>
                                    </CartLineQuantityAdjustButton>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
