import { Card, CardProps } from '@lib/machine-parts/storefront/ui';

export function PaymentMethodCard({ selected, ...props }: CardProps & { selected: boolean }) {
    return (
        <Card
            {...props}
            banner={<input type="radio" className="text-brand-brown pointer-events-none" checked={selected} readOnly />}
            className={selected ? 'border-2 border-brand-brown border-opacity-50 ' : ''}
        />
    );
}
