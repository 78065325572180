'use client';
import { ReactNode } from 'react';

import { Container, SelectIcon } from '@lib/machine-parts/storefront/ui';
import { useTailwindBreakpoint } from '@lib/machine-parts/storefront/utils';
import { Disclosure, Transition } from '@headlessui/react';

function classNames(...classes: (string | undefined)[]) {
    return classes.filter(Boolean).join(' ');
}

interface CheckoutCartContainerProps {
    lineItems: ReactNode;
    discountInput: ReactNode;
    costList: ReactNode;
    proceedButton: ReactNode;
    total?: ReactNode;
}

export function CheckoutCartContainer({
    lineItems,
    discountInput,
    costList,
    proceedButton,
    total,
}: CheckoutCartContainerProps) {
    const isDesktop = useTailwindBreakpoint('md');

    return (
        <Container>
            <div className="w-full lg:max-w-[500px] h-full relative self-end">
                <div className="flex flex-col gap-4 z-20 bottom-0 md:bottom-auto left-0 md:left-auto right-0 md:right-auto fixed md:sticky md:top-24 lg:top-40 md:z-0">
                    {isDesktop ? (
                        <>
                            <div className="max-h-[450px] overflow-y-scroll">{lineItems}</div>
                            <div className="flex flex-col gap-8">
                                {discountInput}
                                {costList}
                                {proceedButton}
                            </div>
                        </>
                    ) : (
                        <Disclosure>
                            {({ open, close }) => (
                                <>
                                    <div className="bg-white w-full flex flex-col gap-2 p-4 z-40">
                                        <Transition
                                            className="transition-all duration-50 overflow-hidden"
                                            enterFrom="transform opacity-0 max-h-0"
                                            enterTo="transform opacity-100 max-h-[75vh]"
                                            leaveFrom="transform opacity-100 max-h-[75vh]"
                                            leaveTo="transform opacity-0 max-h-0"
                                        >
                                            <Disclosure.Panel className="flex-1 flex flex-col gap-4">
                                                <div className="max-h-[60vh] -mx-8 overflow-scroll">{lineItems}</div>
                                                {discountInput}
                                                <div className="px-2">{costList}</div>
                                            </Disclosure.Panel>
                                        </Transition>
                                        <Disclosure.Button
                                            as="div"
                                            className="cursor-pointer flex flex-row items-center gap-2 px-2"
                                        >
                                            {total}
                                            <SelectIcon
                                                aria-hidden="true"
                                                className={classNames(
                                                    !open ? '-rotate-180' : 'rotate-0',
                                                    'h-3 w-3 transform transition-transform duration-700 lg:hidden text-brand-brown',
                                                )}
                                            />
                                        </Disclosure.Button>
                                        {proceedButton}
                                    </div>
                                    {open && (
                                        <div
                                            className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-30 z-30"
                                            onClick={() => close()}
                                        ></div>
                                    )}
                                </>
                            )}
                        </Disclosure>
                    )}
                </div>
            </div>
        </Container>
    );
}
