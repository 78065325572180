import { Container, ContainerProps } from '@lib/machine-parts/storefront/ui';

interface CheckoutContainerProps extends ContainerProps {
    title?: string;
    description?: string;
}

export function CheckoutContainer({ children, title, description, ...props }: CheckoutContainerProps) {
    return (
        <Container {...props}>
            {title && <h1>{title}</h1>}
            {description && <p>{description}</p>}
            {children}
        </Container>
    );
}
