'use client';
import { useCallback, useEffect, useState } from 'react';
import { Button, Input } from '@lib/machine-parts/storefront/ui';

export interface DiscountInputProps {
    code: string;
    error?: boolean;
    onApply: (code: string) => void;
    content: {
        placeholder: string;
        apply: string;
        error: string;
    };
}

export function DiscountInput({
    code,
    error = false,
    onApply,
    content: { placeholder, apply, error: errorMessage },
}: DiscountInputProps) {
    const [discountCode, setDiscountCode] = useState(code);
    useEffect(() => {
        setDiscountCode(code);
    }, [code]);

    const onSubmit = useCallback(() => {
        onApply(discountCode);
    }, [discountCode, onApply]);

    return (
        <div className={`w-full flex flex-col items-stretch`}>
            <div className={`w-full flex flex-row items-stretch gap-3`}>
                <Input
                    placeholder={placeholder}
                    altBorder={true}
                    type={'text'}
                    value={discountCode}
                    className="py-4"
                    onChange={(e) => setDiscountCode(e.target.value)}
                    error={error}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                />
                <Button onClick={onSubmit}>{apply}</Button>
            </div>
            <span className="text-red-500 text-xs mt-2">{error && errorMessage}</span>
        </div>
    );
}
