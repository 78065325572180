'use client';

import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

import { DefaultPhoneForm } from '@lib/machine-parts/storefront/checkout/utils';
import {
    getFormError,
    Input,
    isFormValid,
    ListSelect,
    SHIPPABLE_COUNTRY_CALLING_CODE_LIST_SELECT_OPTIONS,
} from '@lib/machine-parts/storefront/ui';
import { formatPhone } from '@lib/machine-parts/storefront/utils';

interface PhoneErrors {
    required: string;
    invalid: string;
    in_use: string;
}

interface Props<Form extends FieldValues = DefaultPhoneForm> {
    countryCallingCodeEntry: Path<Form>;
    phoneEntry: Path<Form>;
    placeholder?: string;
    isUpdate?: boolean;
    isRequired?: boolean;
    translation?: PhoneErrors;
    onBlur?: (value?: string) => void;
    position?: 'north' | 'south';
}

export type PhoneFormProps<Form extends FieldValues = DefaultPhoneForm> = Props<Form>;

export function PhoneInput<Form extends FieldValues = DefaultPhoneForm>({
    countryCallingCodeEntry,
    phoneEntry,
    placeholder,
    isUpdate = false,
    isRequired = false,
    onBlur,
    position = 'south',
}: PhoneFormProps<Form>) {
    const { setValue, register, watch, formState, getValues } = useFormContext<Form>();
    return (
        <div className="flex gap-4">
            <ListSelect
                id="countryCode"
                options={SHIPPABLE_COUNTRY_CALLING_CODE_LIST_SELECT_OPTIONS}
                selected={watch(countryCallingCodeEntry)}
                handleChange={(value: string) => {
                    setValue(countryCallingCodeEntry, value as PathValue<Form, Path<Form>>, { shouldDirty: true });
                }}
                variant="register"
                position={position}
            />
            <Input
                placeholder={placeholder ?? 'Phone number'}
                data-testid="phoneNumber"
                altBorder={true}
                {...register(phoneEntry, {
                    onBlur: (e) => {
                        if (!onBlur) return;
                        const code =
                            getValues(countryCallingCodeEntry) ??
                            SHIPPABLE_COUNTRY_CALLING_CODE_LIST_SELECT_OPTIONS.at(0)?.value;
                        const number = formatPhone(e?.target?.value ?? '', code)?.phoneNumber;
                        if (!number) {
                            return;
                        }
                        onBlur(number);
                    },
                })}
                error={getFormError<Form>({
                    entryPoint: phoneEntry,
                    formState,
                    validateUntouched: !isUpdate && formState.submitCount > 0 && isRequired,
                })}
                valid={isFormValid<Form>({ entryPoint: phoneEntry, formState, validateUntouched: isUpdate })}
            />
        </div>
    );
}
