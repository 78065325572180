import { CurrencyCode } from '@shopify/hydrogen-react/storefront-api-types';
import { PriceLine, PriceVariant } from '../../atoms';
import { Container } from '@lib/machine-parts/storefront/ui';

export interface CostListProps {
    prices?: { price?: { amount?: string; currencyCode?: string } | null; title?: string }[];
    statement?: string;
    size?: 'small' | 'large';
    sameSize?: boolean;
}

export function CostList({ statement, prices, size = 'large', sameSize }: CostListProps) {
    const small = size === 'small';
    const smallVariant = small ? PriceVariant.ExtraSmall : PriceVariant.Small;
    const largeVariant = small ? PriceVariant.Small : PriceVariant.Big;

    return (
        <Container>
            <div className="flex flex-col items-stretch w-full">
                {prices?.map((price, index, list) => (
                    <PriceLine
                        key={index}
                        priceLine={price.title ?? ''}
                        amount={price.price?.amount}
                        currencyCode={price.price?.currencyCode as CurrencyCode}
                        variant={index === list.length - 1 && !sameSize ? largeVariant : smallVariant}
                        topSpacing={index === list.length - 1 && !sameSize}
                    />
                ))}
            </div>
            {statement && (
                <div className="grid grid-cols-2 grid-rows-1 gap-2 items-stretch w-full">
                    <div>
                        <div className={`text-gray-500 text-sm`}>{statement}</div>
                    </div>
                </div>
            )}
        </Container>
    );
}
