import { ReactElement } from 'react';

import { RichTextBlock } from '@lib/machine-parts/storefront/ui';
import { RichTextBlockProps } from '@lib/machine-parts/storefront/utils';

import { CartCheckoutButtonProps, DiscountInputProps } from '../../atoms';
import { CostListProps } from '../../molecules/footer/CostList';

export interface CartFooterProps {
    costs: ReactElement<CostListProps>;
    discount: ReactElement<DiscountInputProps>;
    checkout: ReactElement<CartCheckoutButtonProps>;
    termsAndConditions: RichTextBlockProps['value'];
}

export function CartFooter({ costs, discount, checkout, termsAndConditions }: CartFooterProps) {
    return (
        <div className="w-full lg:max-w-sm flex flex-col gap-8">
            <div className="w-full flex flex-col gap-8">
                {costs}
                <div className={`w-full flex flex-col items-stretch gap-4`}>
                    {discount}
                    {checkout}
                    <div className={`text-gray-500 text-sm`}>
                        <RichTextBlock value={termsAndConditions} />
                    </div>
                </div>
            </div>
        </div>
    );
}
