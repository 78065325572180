'use client';

import { Button, ButtonVariant, Card, RadioInput } from '@lib/machine-parts/storefront/ui';

interface AddressCardProps {
    title?: string;
    addressLines?: string[];
    selected?: boolean;
    onEdit?: () => void;
    editButtonText?: string;
    onSelect?: true | (() => void);
    dataTestId?: string;
}

const selectedClassnames = 'border-2 border-brand-brown border-opacity-50';

export function AddressCard({
    title,
    addressLines,
    selected,
    onEdit,
    editButtonText = 'Edit',
    onSelect,
    dataTestId,
}: AddressCardProps) {
    return (
        <div
            onClick={typeof onSelect !== 'boolean' ? onSelect : undefined}
            className={onSelect ? 'hover:cursor-pointer' : undefined}
            data-testid={dataTestId}
        >
            <Card
                banner={onSelect ? <RadioInput disabled checked={selected} /> : undefined}
                title={title}
                className={`h-full ${selected ? selectedClassnames : ''}`}
                childrenClassname="h-full"
                footer={
                    onEdit ? (
                        <Button
                            variant={ButtonVariant.None}
                            className="text-brand-brown"
                            onClick={onEdit}
                            data-testid={'edit-address-test-id'}
                        >
                            {editButtonText}
                        </Button>
                    ) : undefined
                }
            >
                {addressLines?.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </Card>
        </div>
    );
}
