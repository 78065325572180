import { Link } from '@lib/machine-parts/storefront/ui/nextjs';

interface CartEmptyProps {
    title: string;
    toHomepage: string;
}

export function CartEmpty({ title, toHomepage }: CartEmptyProps) {
    return (
        <div className="p-7 flex flex-col max-w-3xl place-self-center mx-auto">
            <p className="mb-4 text-lg text-gray-500 text-center">{title}</p>
            <Link href="/" mixpanel={{ eventName: `Button Back To Homepage From Empty Cart` }}>
                {toHomepage}
            </Link>
        </div>
    );
}
