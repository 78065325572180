'use client';

import { CurrencyCode } from '@shopify/hydrogen-react/storefront-api-types';
import { MoneyWrapped } from '@lib/machine-parts/storefront/utils';

export enum PriceVariant {
    Big = 'big',
    Small = 'small',
    ExtraSmall = 'extra-small',
}

const PriceClasses: Record<PriceVariant, string> = {
    [PriceVariant.Big]: 'text-2xl lg:text-3xl',
    [PriceVariant.Small]: 'text-xl lg:text-2xl',
    [PriceVariant.ExtraSmall]: 'text-sm lg:text-lg',
};

export interface PriceLineProps {
    priceLine?: string;
    amount?: string;
    currencyCode?: CurrencyCode;
    variant?: PriceVariant;
    topSpacing?: boolean;
    className?: string;
}

export function PriceLine({
    priceLine,
    amount,
    currencyCode,
    variant = PriceVariant.Small,
    topSpacing = false,
    className = '',
}: PriceLineProps) {
    return (
        <div
            className={`grid grid-cols-2 grid-rows-1 gap-2 items-stretch w-full font-heading ${PriceClasses[variant]} ${
                topSpacing && 'pt-2'
            }`}
        >
            <div>{priceLine && <div>{priceLine}</div>}</div>
            <div className="text-right">
                {amount ? <MoneyWrapped className={className} data={{ amount, currencyCode }} /> : '?'}
            </div>
        </div>
    );
}
