import { Card, RadioInput } from '@lib/machine-parts/storefront/ui';
import { ButtonHTMLAttributes, ReactNode } from 'react';

interface ShippingMethodProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    price?: ReactNode;
    selected?: boolean;
}

export function ShippingMethod({ title, price, selected = false, ...buttonProps }: ShippingMethodProps) {
    return (
        <button {...buttonProps}>
            <Card
                banner={<RadioInput disabled checked={selected} />}
                className={selected ? 'border-2 border-brand-brown border-opacity-50 ' : ''}
            >
                {(title || price) && (
                    <div className={'flex justify-between items-center gap-2'}>
                        {title && <span className={`font-bold`}>{title}</span>}
                        {price}
                    </div>
                )}
            </Card>
        </button>
    );
}
