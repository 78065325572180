'use client';

import { ImageWithFallback } from '@lib/machine-parts/storefront/ui';

interface CartLineImageProps {
    imageUrl?: string;
    width?: number;
    height?: number;
}
export function CartLineImage({ imageUrl, width = 127, height = 127 }: CartLineImageProps) {
    return (
        <ImageWithFallback
            src={imageUrl}
            alt="Product image"
            width={width}
            height={height}
            className="bg-white border border-black border-opacity-5 object-cover object-center"
        />
    );
}
