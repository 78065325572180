import { PropsWithChildren, ReactElement } from 'react';

import { Card, CardProps, LabelledItem, LabelledItemProps } from '@lib/machine-parts/storefront/ui';

export interface InfoCardProps {
    title?: string;
    description?: string;
    items: Record<'email' | 'phone' | 'firstName' | 'lastName' | 'vat', LabelledItemProps>;
    icon?: ReactElement;
}

export function InfoCard({
    title,
    description,
    items,
    icon,
    children,
    ...props
}: PropsWithChildren<InfoCardProps & Omit<CardProps, 'title' | 'description'>>) {
    return (
        <Card {...props} title={title} description={description} icon={icon}>
            <div className="grid grid-flow-row grid-cols-1 md:grid-flow-row md:grid-cols-2 gap-4" data-testid="info">
                <LabelledItem label={items.email.label} value={items.email.value} data-testid="email" />
                <LabelledItem label={items.phone.label} value={items.phone.value} data-testid="phone" />
                <div className="flex flex-row gap-8">
                    <LabelledItem label={items.firstName.label} value={items.firstName.value} data-testid="firstName" />
                    <LabelledItem label={items.lastName.label} value={items.lastName.value} data-testid="lastName" />
                </div>
                <LabelledItem label={items.vat.label} value={items.vat.value} data-testid="vat" />
            </div>
            {children}
        </Card>
    );
}
